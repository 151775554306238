
.create-client-form-box {
    padding: $padd40;
    padding-top: 36px;
    @include media-breakpoint-down(sm) {
        padding: $padd10;
        border: 0;
    background: transparent;
    border: 1px solid #000000 !important;
    border-radius: 0;
    padding: 12px !important;
    padding-top: 40px !important;
    }
}

.new-client-form-wrapper .create-client-form-box h2 img {
    margin-right: $marg10;
}

.new-client-form-wrapper h2 {
    color: $ltblue2;
    border-bottom: 1px solid $ltgrey3;
    padding-bottom: $padd5;
    margin-bottom: $marg20;
    @include media-breakpoint-down(sm) {
        font-size: $font20;
    }
}

.new-client-form-wrapper h3 {
    line-height: 20px;
    padding-top: 15px;
    font-weight: 400;
    &.add-homeowner{
        margin: 1.5rem 0;
    }
}


.section-from-box {
    padding-bottom: 25px
}



.section-from-box .upload-wrap .upload-box {
    min-width: 275px;
    margin: auto;
    color: $labelcolor;
    font-size: $font14;

    @include media-breakpoint-down(sm) {
        text-align: center;
        width: 100%;

    }
}
.upload-wrap{
    width: 280px;
    margin: 0 auto;
}
.section-from-box .upload-wrap .upload-box img {
    margin-right: $marg10;
}

.existing-insall-box {
   
    border-left: 2px solid #E9E9E9;
    /* height: 175px; */
    display: flex;
    flex-direction: column;
    margin-top: 38px;
    padding-bottom: 4px;
    padding-top: 15px;

    @include media-breakpoint-down(sm) {
        margin: auto;
        padding-top: 21px;
    }
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    margin-top: 0;
}


.upload-btn-wrapper .btn {
    background-color: white;
    border-radius: 8px;
    font-weight: bold;
    background-image: url(../Assets/Images/add_cloud.svg);
    background-repeat: no-repeat;
    background-position: center 10px;
    width: 300px !important;
    height: 100px;

    @include media-breakpoint-down(sm) {
        width: 185px;

    }

}

.upload-btn-wrapper span {
    position: absolute;
    bottom: 20px;
    left: 40px;
    font-size: 14px;
    color: #9b9b9b;

    @include media-breakpoint-down(sm) {
        bottom: 6px;
        left: 0px;
        text-align: center;

    }
}

.upload-btn-wrapper input[type=file] {
    font-size: 63px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

/* setting page */




.setting-profile-update {
    position: relative;
    display: inline-block;
    border-top: solid 1px #e9e9e9;
    text-align: center;
    width: 100%;
}


.setting-profile-update input[type=file] {
    font-size: 16px;
    position: absolute;
    left: -23px;
    top: -96px;
    opacity: 0;
    height: 150px;
    z-index: 999;
    width: 150px;
    cursor: pointer;
}

.personal-setting-wrap {
    margin: $marg70 $marg90 $marg90;

    @include media-breakpoint-down(sm) {
        margin: $marg10;
    }
}

.profile-setting {
    border: 1px solid $ltgrey3;
    border-radius: 4px;
    text-align: center;
    min-height: 150px;
    cursor: pointer;

    @include media-breakpoint-down(md) {
    cursor: pointer;
    width: 145px;
    overflow: hidden;
        margin: auto;
        margin-bottom: $marg30;
        margin-top: $marg30;
    }
}

.profile-setting .profile-pic {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: relative;
    background: url(./../Assets/Images/twitter.svg) no-repeat 0 0;
    background-position: center center;
    background-size: 100%;
    margin-top: 9px;
    margin-left: 5px;
    margin-bottom: 9px;
    .UserAvatar--inner{
        line-height: 151px !important;
        font-size:60px
    }
}

.company-setting-wrap {
    margin: $marg10 $marg90 $marg0;
    @include media-breakpoint-down(sm) {
        margin: $marg10;
    }
}


.add-user-img-btn {
    height: 16px;
}

.employee-account-info {
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    border: 1px solid $ltgrey3;
    margin-top: $marg40;
    margin-bottom: $marg40;
    overflow: auto;
    table {
        margin-bottom: $marg0 !important;
        th {
            border: 0px;
            height: 36px;
            img{
                width: 13px;
                margin-left: 7px;
                margin-top: -1px;
            }
            @media (max-width: 768px) {
                background-color: $ltgrey3;
            }
    }
}
&.emp-header{
margin-top: 0px;
background-color: #ffffff;

}
}

.detail-info-label.defaultPM.margb10-m0bile{
    float: left;
    margin-right: 30px;
}


.detail-info .detail-info-label {
    font-size: 1.125rem !important;
    color: black !important;
    &.comp-setting{
     height: 16px;
     width: 140px;
     font-size: 14px!important;
     font-weight: 300;
     line-height: 16px;
     text-align: center!important;
    }
}
.bold-text {
    color: #000 !important;
    font-weight: 600;
}

.centered {
    text-align: center;
}

.detail-info .form-wrap {
    width: 90%;
    margin: auto;
    @include media-breakpoint-down(sm) {
        width: 100%;
    }
}

.detail-info .form-wrap .form-group textarea {
    border: 1px solid $labelborder !important;
    height: auto;
}

.v-h-center {
    justify-content: center;
    align-items: center;
}

.detail-info-box {
    display: flex;
    margin: 0 0 $marg20;
    align-items: center;
    .sign-text{
        font-size: 15px;
        text-align: left;
        width: 100%;
        margin-left: 30px;
    }
    .nav-tabs{
        background-color: #f4f7fa;
        border-bottom: 1px solid #DFE6EE;
        border-top: 1px solid #DFE6EE;
        width: 100%;
        .nav-item{
            margin-bottom: -1px;
            flex-grow: 1;
            text-align: center;
            .nav-link{
                font-weight: 600;
                &.active{
                    // border: none;
                    border-radius: 0;
                    color: #ff6f47;
                }
            }
        }
    }
    textarea{
        border: 1px solid lightgray !important;
        margin-top: 5px;
    }
    .text{
        margin-top: 25px;
        text-align: left;
        width: 100%;
        padding-left: 16px;
        box-sizing: border-box;
        font-size: 13px;
    }
}

.electric-utility {
    height: 70px !important;
}

.text-right-form {
    text-align: right;

    @include media-breakpoint-down(sm) {
        text-align: left;
    }
}

.file-error {
    color: Red;
}

.folderList {
    height: auto;
    padding: 10px;
    overflow: hidden;
    position: relative;
    padding-top: 30px;
    list-style-type: none;
    text-align: left;
    width: 300px;
    margin: 0 auto;

}
.folderList:hover {
    overflow: auto;
}

.sync-btn-wrapper {
    margin-bottom: 15px;
    margin-top: 15px;
    text-align: center;
}

.help {
    margin-left: 10px;
    border-radius: 50%;
    border: 1px solid #adacac;
    padding: 2px 5px 2px 5px;
    font-size: 10px;
    color: #adacac;
    position: relative;
    top: -5px;
    cursor: pointer;
}

.folderList .header {
    font-size: 15px;
    font-weight: bold;
}

.close-folder-section {
    float: right;
    color: red;
    cursor: pointer;
    font-weight: bold;
    position: absolute;
    right: 0;
    top: -3px;
}
.document-section {
    min-height: 100px;
    margin-top: 15px;
    margin-bottom: 15px;
    &.fusion{
        min-height: 30px;
    }
}

.resource-bg .carousel .slider-wrapper.axis-horizontal .slider .slide{
    @include media-breakpoint-down(sm) {
        border-right: 10px solid #fff;
        height: auto;
        -ms-touch-action: auto;
        touch-action: auto;
    }
}

.slick-next:before, .slick-prev:before{
    color: #9b9b9b;
    @include media-breakpoint-down(sm) {
        color:transparent;}
}
.slick-initialized .slick-slide{
    @include media-breakpoint-down(sm) {

    height: auto;
}
}
.slick-initialized .slick-slide:first-child{
    @include media-breakpoint-down(sm) {

    border-left: solid 0px #fff;
    border-right: solid 0px #fff;
    height: auto;
}}
.slick-initialized .slick-slide:last-child{
    @include media-breakpoint-down(sm) {

    border-right: 0
}}
.slick-initialized .slick-slide:nth-child(2){
    @include media-breakpoint-down(sm) {

    border-left: 0
}
}

.nextClassButton{
    position: absolute;
    top: 50%;
    left: 100%;
    color: red;
    transform: translate(5px, -50%);
}
.slick-track{
    @include media-breakpoint-down(sm) {

    left: -10px;
    }
}
.slick-next{
    @include media-breakpoint-down(sm) {

    display: none !important
}}

.dropdown_widthPM{
    width: 320px!important;
    margin-left: 10px;
}

.ref-amount-company{
    box-sizing: border-box;
    height: 1px;
    width: 80px!important;
    border: 1px solid #D8D8D8;
}

.comp{
    padding-top: 54px;
}
.text-pad{
    padding-left: 18px;
}