a {
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
}
.white-box-wrapper {
	background-color: white;
	width: 100%;
	margin: 90px auto;
	
		&.mobileView {
		    @include media-breakpoint-down(sm) {
		        display: none;
		}
	}
}
.sys-notification-wrapper {
	.left-menu-box {
		position: relative;
		padding: 42px 30px 30px 62px;
		&::after {
			position: absolute;
			content: '';
			height: 100%;
			width: 2px;
			background-color: #F5F8FB;
			top: 0;
			right: 0;
			text-align: left;
		}
	}
	h2 {
		font-size: 18px;
		line-height: 20px;
		color: #6A6A6A;
		border-bottom: 1px solid #E0E0E0;
		padding: 10px 0;
		img {
			margin-right: 10px;
		}
	}
	.left-menu {
		margin: 0;
		padding: 0;
		.shiftRight{
			margin-left: 20px;
		}
		li {
			list-style: none;
			color: #6A6A6A;
			font-size: 14px;
			line-height: 18px;
			padding: 10px 0;
			font-weight: 700;
			a {
				color: #6A6A6A;
				&:hover {
					color: #00142D;
				}
			}
			a.active {
				color: #00142D;
			}
		}
	}
	.mt2 {
		margin-top: 58px;
	}
	.right-content-box {
		h2 {
			color: #00142D;
			font-size: 24px;
			line-height: 20px;
		}
		.content-box {
			border: 1px solid #E0E0E0;
			margin-top: 32px;
			@media screen and (max-width: 767px) {	
				border: 0px!important;
				margin-top: 0px!important;	
			}
			.mob-box {
				background-color: #f4f7fa;
				color: #4A4A4A;
				font-size: 18px;
				line-height: 18px;
				font-weight: 300;
				padding: 15px!important;
				@media screen and (max-width: 767px) {
					display: none;
				}
			}
			.content-section-box {
				font-size: 14px;
				line-height: 18px;
				width: 450px;
				margin: 0 auto;
				padding: 10px 10px 40px 10px;
				.form-control {
					border-left: 0;
					border-right: 0;
					border-top: 0;
					border-radius: 0;
					box-shadow: none;
					max-width: 61px;
					font-size: 14px;
					line-height: 18px;
					&:focus {
						border-left: 0;
						border-right: 0;
						border-top: 0;
						border-radius: 0;
						box-shadow: none;
						max-width: 61px;
						font-size: 14px;
						line-height: 18px;
					}
				}
				&.pad-20{
					padding-bottom: 20px!important;
				}
			}
		}
	}
}
.right-content-wrapper {
	padding: 22px 15px 30px 0px;
	margin-left: 3.81%;
	min-height: calc(100vh - 1px);
}
.p0 {
	padding: 0;
}
.sys-DesktopView {
	@include media-breakpoint-down(sm) {
		display: none;
}
}

.plan.detail{
	border: none !important;
	.color{
		margin: 0;
		.cmn-form-grp-input{
			display: inline-block;
		}
		.color-input{
			width: calc(100% - 40px);
			float: left;
		}
		.color-view{
			width: 30px;
			height: 30px;
			margin-left: 10px;
			margin-top: 8px;
			float: right;
			border: 1px solid;
			border-radius: 5px;
		}
	}
}

.edOption-container{
	position: absolute;
	left: 15px;    
    top: 130px;
    max-width: 120px;
    font-size: 14px;
	.slide-number{
		margin-bottom: 5px;
    	cursor: pointer;
    	color: #ff6f47;
    	margin-left: 5px;
    	list-style: none;
    	font-size: 14px;
    	line-height: 18px;
    	padding: 10px 0;
		&.active{
			font-weight: 700;
			color: #ff6f74;
		}
	}
}

button + button {
	margin-left: 20px;
}

.ant-btn.ant-btn-default.dynamic-delete-button {
	border: 0 none;
    margin-bottom: 12px;
    margin-left: 6px;
}