.ant-switch {
    border-radius: 100px !important;
    .ant-switch-inner{
        display: none;
    }
}

.ant-switch-checked {
    background-color: #ff6f47 !important;
}

.body-header.dashboard-nav-wrapper .bg-light {
    position: relative;
    border: 0;
    z-index: 1;
}

.nav-header-wrapper.dashboard-nav-wrapper{
    top: 0;
    z-index: 2;
    width: 100%;
    .admin-view{
        width: 100%;
        height: 40px;
        text-align: center;
        color: #4A4A4A;
        font-size: 26px;
        font-weight: bold;
        line-height: 40px;
        background-color: #FFCC00;
        .close-icon{
            float: right;
            line-height: 40px;
            margin-right: 10px;
            cursor: pointer;
        }
    }
    .haze{
        z-index: -1;
        position: relative;
    }
}

.body-header.dashboard-nav-wrapper .navbar-brand {
    @include media-breakpoint-down(sm) {
        height: 29px;
        width: 18px;
        margin: auto;
        margin-top: 1px;
        background-size: 18px;
        background-repeat: no-repeat;
        padding: 0;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);

        &.publicReferral {
            background: url(../Assets/Images/bodhi_small.svg);
            background-repeat: no-repeat;
            width: 72px;
        }

    }


}

#navbarhomeOwnerContent .menu-mobile .nav-item {
    width: 100%;
    text-align: left;
    border-bottom: solid 1px #e0e0e0;
    border-right: 0;
}

#navbarhomeOwnerContent .nav-item a {
    opacity: 0.9;
    color: #000000;
    font-size: 14px;
    line-height: 35px;
    padding-right: 15px;
    padding-left: 15px;
}

#navbarhomeOwnerContent .nav-item a.active {
    font-weight: 500;
    text-decoration: underline;

}

#navbarhomeOwnerContent .nav-item:last-child a {
    border-right: 0;
}

#navbarhomeOwnerContent .menu-mobile {
    height: calc(100vh - 110px);
}

.white-box.installerView-homeowner {
    @include media-breakpoint-down(sm) {
        margin: 0px;
        margin-bottom: 0;
        padding-bottom: 0 !important;
    }
}

.homerowner-view .mobile-view-only .white-box-secondary {
    @include media-breakpoint-down(sm) {
        border: solid 1px black
    }
}

.homeowner-title {
    @include media-breakpoint-down(sm) {
        display: block;
        margin-bottom: 7px;
    }
}

.desktop-homewoner-nav {
    float: right;
}



.collapse.navbar-collapse.display-block {
    display: block !important;

    @include media-breakpoint-down(md) {
        display: none !important;
    }
}

.installerView-homeowner+.pm-home-owner-wrapper .create-new-account-wrapper {
    width: 100%;
}

.installerView-homeowner+.pm-home-owner-wrapper .create-new-account-wrapper .progress-wrapp {
    width: auto;
}



.wrapper-installer-data {
    @include media-breakpoint-down(sm) {
        margin-left: 15px;
        margin-right: 15px;
        padding-top: 30px !important;
    }
}

.pm-home-owner-wrapper.wrapper-installer-data .dashboard-nav-wrapper .navbar-toggler {
    @include media-breakpoint-down(sm) {
        top: 0px;
        overflow: hidden;
    }
}

.installerView-homeowner.white-box .heading {
    @include media-breakpoint-down(sm) {
        border: 0;
        margin-bottom: 20px;
    }
}

.homeownerFooter {
    position: relative;
}

.homeownerFooter .footer-17 {
    @include media-breakpoint-down(sm) {
        position: absolute;
        box-shadow: none;
        border-top: 1px solid #E0E0E0;
        z-index: 1;
    }
}

.create-new-account-wrapper h2.createBodhiAccount {
    opacity: 0.9;
    color: #000000 !important;
    font-size: 24px !important;
    line-height: 28px !important;
    text-align: center;

    @include media-breakpoint-down(sm) {
        color: rgba(0, 0, 0, 0.87) !important;
        font-size: 14px !important;
        line-height: 20px !important;
    }
}

.homeOwnerEmail {
    max-width: 336px;
    margin: 0 auto;

    @include media-breakpoint-down(sm) {
        max-width: 285px;
    }
}

.homeowner-account {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    line-height: 20px;
    margin-top: 12px;
    margin-bottom: 15px;

    &.mid-loc {
        text-align: center;
    }
}

.add-new-home-owner{
    width: 960px !important;
    .create-client-form-box {
        @include media-breakpoint-down(sm) {
            border: 0 !important;
        }
    } 
    .width-100{
        max-width: 100%;
        flex:0 0 100%;
    }
}



.btn-center-mobile {
    @include media-breakpoint-down(sm) {
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
    }

}

.btn-center-mobile .btn {
    @include media-breakpoint-down(sm) {
        margin-right: 0;
    }
}

.map-bg .map-drop-down span i {
    font-size: 20px;
}

.up-arra.map-drop-down i {
    transform: rotate(180deg)
}

.up-arra.map-drop-down.collapsed i {
    transform: rotate(0deg);
}

.margin-bottom-33 {
    margin-bottom: 33px !important;

    @include media-breakpoint-down(sm) {
        margin-bottom: 10px !important;
    }
}

.select-state>div {
    border-width: 0 !important;
}

.trakyourrefferals-outer,
.surveyResult-outer,
.socialShare-outer {
    position: relative;
}

.trakyourrefferals-outer .justify-content-end {
    @include media-breakpoint-down(sm) {

        position: absolute;
        top: -11px;
        right: 9px;
    }
}

.trakyourrefferals-outer.heading h2 {
    @include media-breakpoint-down(sm) {
        margin-top: 22px;
    }
}

.surveyResult-outer .justify-content-end {
    @include media-breakpoint-down(sm) {

        position: absolute;
        top: -25px;
        right: 9px;
    }
}

.surveyResult-outer.heading h2 {
    @include media-breakpoint-down(sm) {
        margin-top: 10px;
        margin-bottom: 10px !important
    }
}

.socialShare-outer .justify-content-end {
    @include media-breakpoint-down(sm) {

        position: absolute;
        top: -25px;
        right: 9px;
    }
}

.socialShare-outer.heading h2 {
    @include media-breakpoint-down(sm) {
        margin-top: 10px;
        margin-bottom: 10px !important
    }
}



.personalSetting .create-client-form-box {
    @include media-breakpoint-down(sm) {
        border: 0 !important;
    }
}

.personalSetting {
    @include media-breakpoint-down(sm) {
        margin-top: -29px;
    }
    &.mobileView{
        @include media-breakpoint-up(md) {
            display: none;
        }
    }
}

.btn-mobiel .btn-secondary {
    @include media-breakpoint-down(sm) {

        margin-right: 0 !important;
    }
}

.text-capitial {
    text-transform: capitalize;
}

.homeowner-view-wrapper .mobile-view-only .white-box-secondary {
    @include media-breakpoint-down(sm) {
        border: 0;
    }
}

.homeowner-view-wrapper .pm-home-owner-wrapper,
.homeowner-view-wrapper .pm-home-owner-wrapper .container {
    @include media-breakpoint-down(sm) {

        padding-left: 0;
        padding-right: 0;
    }
}

.homeowner-view-wrapper .pm-home-owner-wrapper .container .white-box-secondary {
    @include media-breakpoint-down(sm) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
    height: 100%;
}

.carousel .slide {
    background: none;
}

.donutChart {
    width: 100% !important;

    @include media-breakpoint-down(sm) {

        width: 100% !important;
    }
}

.donutChart .graph-position {
    transform: translate(165px, 165px) !important;
    &.referral{
        transform: translate(50%, 50%) !important;
    }
    @include media-breakpoint-down(sm) {
        transform: translate(50%, 165px) !important;
    }
}

span.aM3 {
    @include media-breakpoint-down(sm) {
        bottom: 14% !important;
        left: 6% !important;
    }
}

span.aM6 {
    @include media-breakpoint-down(sm) {
        top: 50%;
        left: -2% !important;
    }
}

span.pM9 {
    @include media-breakpoint-down(sm) {

        bottom: 14% !important;
    }
}

span.pM6 {
    @include media-breakpoint-down(sm) {
        right: -2% !important;
    }
}

.control-arrow.control-next,
.control-arrow.control-prev {
    top: -140px !important;

}

.spacingMobile {
    @include media-breakpoint-down(sm) {
        margin-left: 15px;
        margin-right: 15px;
    }
}