
@import "../scss/bootstrap-reboot.scss";

@import "../dist/css/bootstrap.css";

//custom variable
@import "_custom-variable.scss";

//custom variable
@import "_custom.scss";

// comman css
@import "_comman.scss";

// login css
@import "_login.scss";


// footer css
@import "_footer.scss";


// header css
@import "_header.scss";

// developer css
@import "_developer.scss";

// PM dashboard css
@import "_pm-dashboard.scss";

// PM home owner css
@import "_pm-home-owner.scss";

// PM new client installer
@import "_pm-new-client-installer.scss";

// PM new install installer
@import "_pm-new-install-installer.scss";

//pm-Account-creation-Installer.scss
@import "_pm-Account-creation-Installer.scss";

@import "_homeowner-onboarding.scss";

@import "_homepage.scss";
@import "_installer.scss";

@import "_publicReferral.scss";

@import "_companySettings.scss";

@import "_salesforce.scss";
@import "_bodhiJourneyTrigger.scss";

@import "campaign-dashboard.scss";
@import "_campaign-create-new.scss";
@import "_campaign-existing.scss";

@import "_adminPanel.scss";

@import "_fonts.scss";


