a,
a:hover {
  text-decoration: none;
}

.monitoring{
  height: 100%;
}

.profile-detail-box {
  color: #000;
  font-size: 14px;
  line-height: 20px;
  padding-top: 150px;
  @include media-breakpoint-down(sm){
    margin-top: 0px;
  }
  h1 {
    color: #00142D;
    font-size: 30px;
    line-height: 40px;
    font-weight: 600;
  }
  .company-link{
    color: #00142D;
    font-size: 14px;
    line-height: 16px;
    margin-top: 7px;
    cursor: pointer;
  }
}
.profile-detail-box .personal-detail-box {
  position: relative;
  padding-left: 30px;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 180px;
    width: 1px;
    background: #979797;
    content: "";
  }
  .add-user {
    cursor: pointer;
    margin-top: 4px;
  .add-btn {
    height: 19px;
  }
  .text {
    display: inline;
    color: #ff6f47;
    font-size: 14px;
    line-height: 16px;
    margin-left: 7px;

  }
}

  .detail-box {
    .action-btn{
      color: #00142D;
      font-weight: 600;
    }
    p {
      margin: 0;
      padding: 0;
      font-size: 14px;
      line-height: 20px;
      &:nth-child(1) {
        font-weight: 600;
        margin-top: 5px;
      }
      &:nth-child(2) {
        font-weight: 400;
        word-break: break-all;
      }
    }
  }
}

.profile-detail-box .white-box {
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
  text-align: center;
			
}

.profile-detail-box .white-box .number {
  color: #00142D;
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
}

.profile-detail-box .white-box p {
  margin-top: 15px;
  margin-bottom: 0;
}

.profile-detail-box .white-box .icon{
  img{
    height: 30px;
    width: 17.44px;
    margin-top: 5px;
    &.survey{
      height: 25px;
      width: 25px;
    }
    &.share{
      height: 25px;
      width: 17.74px;
    }
    &.referral{
      height: 25px;
      width: 22.58px;
    }
  }
}

.profile-detail-box .white-box .stats{
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1.5px;
  line-height: 40px;
  margin-left: 13px;
}

.detail-box{
  max-height: 412px;
  overflow: auto;
}

div.detail-box::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00142D;
  border-radius: 10px;
}



.fleetBox{
  text-align: left !important;
  box-sizing: border-box;
  margin: 0;
  box-shadow: none !important;
  border: 1px solid #DFE6EE !important;
  border-radius: 10px;
  padding: 20px 0px !important;
  &.hideMargin{
    border: 0 !important;
    padding: 0 !important;
  }
  &.mapView{
    padding: 20px 0px !important;
    .title{
      margin: 0 !important;
    }
  }
  .title{
    letter-spacing: 0.26px;
    line-height: 17px;
    margin-bottom: 10px!important;
    font-size: 14px;
    padding: 0;
    display: block;
  }
  img{
    height: 25px;
    width: 25px;
  }
  .row{
    margin: 0;
    text-align: center;
    &.cursor{
      cursor: pointer;
    }
  }

  .total{
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    &.red{
      color: #FF6F47;
    }
    &.yellow{
      color: rgb(227, 172, 0);
    }
  }
  .total-account{
    color: #4A4A4A;
    font-size: 12px;
    line-height: 16px;
    margin-top: 4px;
  }
}


/* tab wrapper */

.tab-wrapper {
  width: 100%;
  text-align: right;
}
.tab-wrapper ul {
  margin: 60px 0 15px 0;
  padding: 0;
}

.tab-wrapper ul li {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline;
  text-align: right;
  font-size: 14px;
  line-height: 20px;
  color: #7A7A7A;
  margin-right: 50px;
}
.tab-wrapper ul li a {
  color: #6a6a6a;
  text-decoration: none;
}
.tab-wrapper ul li a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.tab-wrapper ul li a span {
  display: inline-block;
  margin-right: 6px;
}
.tab-wrapper ul li.home-owner a .icon {
  background-image: url(../Assets/Images/person_heart_gray.svg);
  width: 19px;
  height: 26px;
  background-size: 19px 26px;
  background-repeat: no-repeat;
}

.tab-wrapper ul li.sys-crm a .icon {
  background-image: url(../Assets/Images/sync-crm-icon.svg);
  width: 29px;
  height: 26px;
  background-size: 29px 26px;
  background-repeat: no-repeat;
}

.tab-wrapper ul li.edit-detail a .icon {
  background-image: url(../Assets/Images/edit-details-icon.png);
  width: 29px;
  height: 26px;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  margin: 0;
}

.tab-wrapper ul li.delete a .icon {
  background-image: url(../Assets/Images/delete-icon.png);
  width: 23px;
  height: 23px;
  background-size: 15px 18px;
  background-repeat: no-repeat;
  margin: 0;
}

/* profile detail */
.profile-detail-wrapper {
  .edit-link {
    position: relative;
    color: #9b9b9b;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    &::before {
      content: "";
      background-size: 14px 14px;
      display: inline-block;
      width: 14px;
      height: 14px;
      margin-right: 5px;
      margin-left: 10px;
    }
    &.inactive::before{
      background-image: url(../Assets/Images/edit-details-icon.svg);
    }
    
    &.active{
      color: #00142D;
      &::before{
        background-image: url(../Assets/Images/save_icon.svg);
      }
    }
  }
  .monitoring-profile-box {
    padding: 50px 50px 38px 50px;
    border-bottom: 1px solid #e9e9e9;
  }
  h2 {
    font-weight: 200;
    color: #4a4a4a;
  }
}

.profile-detail-wrapper .monitioring-detail-box {
  display: flex;
  justify-content: space-between;
  .meter-type{
    width: 110px;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}

.profile-detail-wrapper .monitioring-detail-box > div p:nth-child(1) {
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 26px;
}

.profile-detail-wrapper .monitioring-detail-box > div p:nth-child(2) {
  font-weight: normal;
  margin-bottom: 0;
}

.profile-detail-wrapper .monitioring-detail-box a.test-meter-link {
  margin-top: 30px;
  width: 220px !important;
}

.profile-detail-wrapper .monitioring-detail-box .form-section-wrapper label {
  margin-bottom: 0;
  margin-top: 28px;
  font-weight: 600;
}

.notify-box {
  margin-top: 1rem;
  position: relative;
  .forgot-password{
    margin: 10px auto;
    margin-left: 46px;
  }
}


.notify-box p:nth-child(1) {
  font-weight: 600;
  margin-bottom: 0;
}
.notify-box p:nth-child(2) {
  font-weight: normal;
}



.profile-detail-wrapper .notification-doc-box{
  padding: 32px 0px 20px 0px;

  .activity-log{
    .month {
      color: #ff6f47;
      display: block;
    }
    .box-Shadow{
      margin: 10px 8px;
      padding: 10px 13px 0;
    }
    .dashboard-profile-image{
      top: -20px;
    }
    .log{
      margin-top: 5px;
      display: block;
      margin-left: 6px;
    }
    .note-log{
      display: inline-block;
      margin-left: 15px;
    }
    .note-icon{
      float: right;
      img{
        height: 15px;
        cursor: pointer;
        margin-left: 9px;
      }
    }
  }
  .profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 16px;
  }
  .media h5 {
    font-size: 1.125rem;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 0;
    line-height: 21px;
  }
  .media p {
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 5px;
    font-weight: 400;
  }
  .installer-document-box {
    border-left: 1px solid #e9e9e9;
    padding-left: 30px;
    word-break: break-all;
  }
} 
.doc-content {
  margin-top: 20px;
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      color: #4a4a4a;
      font-size: 14px;
      line-height: 20px;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 18px;
      a {
        color: #4a4a4a;
        word-break: break-all;
        display: initial;
        span {
          display: block;
        }
      }
      .file{
      margin-bottom: 5px;
      font-weight: 300;
      line-height: 22px;
      }
      img {
        margin-right: 10px;
      }
    }
  }
}

/* edit form */

.form-section-wrapper .form-wrap label {
  font-size: 14px;
  color: #9b9b9b;
  text-transform: none;
  line-height: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}
.form-section-wrapper .form-wrap .caret-icon {
  position: relative;
}
.form-section-wrapper .form-wrap label.caret-icon:after {
  position: absolute;
  height: 12px;
  width: 12px;
  content: url(../Assets/Images/grey-down-arrow.svg);
  right: -20px;
  top: -3px;
}

.form-section-wrapper .form-control-wrap {
  position: relative;
}

.form-section-wrapper .form-control-wrap i {
  position: absolute;
  right: 0;
  top: 10px;
  display: none;
}

.form-section-wrapper .form-control-wrap .form-control {
  display: block;
  width: 100%;
  height: 30px;
  padding: 3px 10px;
  font-size: 14px;
  line-height: 16px;
  color: #495057;
  border: 0px;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: inherit;
  position: relative;
}

.form-section-wrapper .form-section-box {
  border-bottom: 2px solid #f5f8fb;
}

.form-section-wrapper .form-section-box .form-container {
  border-right: 2px solid #f5f8fb;
  padding: 50px;
}
.form-section-wrapper .form-section-box .uplolad-container {
  padding: 50px;
  text-align: center;
}

.profile-detail-wrapper .form-section-wrapper .form-section-box .form-container h2,
.profile-detail-wrapper .form-section-wrapper .form-section-box .uplolad-container h2 {
  margin-bottom: 30px;
}




.upload-btn-wrapper input[type="file"] {
  font-size: 63px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.upload-wrap a {
  background: #00142D;
  min-height: 40px;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  text-decoration: none;
  display: block;
  height: 40px;
  width: 280px;
  margin: 0 auto;
  padding: 6px;
}

.solar-system-wrapper .form-container,
.electric-wrapper .form-container {
  padding: 50px;
  border-bottom: 2px solid #f5f8fb;
}
.assign-project-wrapper .form-container {
  padding: 50px;
}
.solar-system-wrapper .form-container h2,
.electric-wrapper .form-container h2,
.assign-project-wrapper .form-container h2 {
  margin-bottom: 30px;
}

.btn{
  font-size: 14px;
  border-radius: 10px !important;
  font-weight: bold;
  width: 150px !important;
	height: 40px;
	line-height: 40px;
	padding: 0;
  text-align: center;
  color: #00142D;
  @include media-breakpoint-down(md) {
    &.setting-btn{
      margin: 15px 15px 0 0 !important;
    }
  }
  &.auto-width{
    min-width: 150px;
    width: auto !important;
    padding: 0 10px;
  }
}

.btn-secondary,.btn-secondary:not(:disabled):not(.disabled):active {
  border-color: #FFD110;
  background: white;
  color: #00142D;
  &:hover {
    background: #FFD110;
    color: #00142D;
    border-color: #FFD110;
  }
  &:focus {
    background: #fff;
    color: #00142D;
    border-color: #FFD110;
  }
}

.btn-primary,.btn-primary:not(:disabled):not(.disabled):active {
  border-color: #FFD110;
  background: #FFD110;
  color: #00142D;
  &:hover,&:focus  {
    border-color: #FFDB40 !important;
    color: #00142D;
    font-size: 14px;
    background: #FFDB40 !important;
  }
  &.w-200{
    width: 200px !important;
  }
}


@media screen and (max-width: 800px) {
  .profile-detail-wrapper .monitioring-detail-box > div {
    padding-right: 20px;
  }
  .profile-detail-wrapper .monitioring-detail-box > div p:nth-child(2) {
    word-break: break-all;
  }
  .notify-box::after {
    right: 0;
  }
 
  .profile-detail-wrapper h2 {
    font-size: 25px;
  }
  .upload-btn-wrapper .btn, .upload-wrap a {
    width: 200px;
  }
}

@media screen and (max-width: 767px) {
  .button-box .btn {
    margin-bottom: 15px;
  }
  .upload-btn-wrapper .btn, .upload-wrap a {
    width: 150px;
  }
  .assign-project-wrapper .form-container,
  .form-section-wrapper .form-section-box .form-container,
  .form-section-wrapper .form-section-box .uplolad-container,
  .profile-detail-wrapper .monitoring-profile-box,
  .solar-system-wrapper .form-container,
  .electric-wrapper .form-container,
  .assign-project-wrapper .form-container,
  .profile-detail-wrapper .monitoring-profile-box {
    padding: 20px;
  }


  .assign-project-wrapper .form-container .form-wrap,
  .form-section-wrapper .form-section-box .form-container .form-wrap ,
  .form-section-wrapper .form-section-box .uplolad-container .form-wrap,
  .profile-detail-wrapper .monitoring-profile-box .form-wrap,
  .solar-system-wrapper .form-container .form-wrap ,
  .electric-wrapper .form-container .form-wrap,
  .assign-project-wrapper .form-container .form-wrap,
  .profile-detail-wrapper .monitoring-profile-box .form-wrap 
  {
    padding-left: 20px;
  }



  .profile-detail-box .white-box .engagement-scroe-box .icon,
  .profile-detail-box .white-box .referrals-box .icon,
  .profile-detail-box .white-box .surveys-box .icon,
  .profile-detail-box .white-box .social-shares-box .icon {
    display: none;
  }
  .profile-detail-box .personal-detail-box,
  .profile-detail-box .personal-detail-box .detail-box p,
  .profile-detail-box,
  .profile-detail-box .white-box {
    font-size: 12px;
    line-height: 14px;
  }

  .col-xs {
    width: 50%;
  }


  .profile-detail-box .white-box {
    margin-top: 35px;
    justify-content: inherit;
    align-items: inherit;
    height: 90px;
  }
  .profile-detail-box .white-box div {
    flex-grow: 1;
    flex-basis: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .name-adddres-box {
    padding-left: 30px;
  }

  .profile-detail-box .personal-detail-box {
    padding-left: 20px;
  }

  .profile-detail-box .white-box .content {
    display: flex;
    flex-direction: column-reverse;
    width: 90%;
  }
  .profile-detail-box .white-box .number {
    margin-top: 0;
  }
  .profile-detail-wrapper .monitioring-detail-box {
    display: block;
    padding: 0 20px;
  }

  .tab-wrapper ul li .icon {
    display: none;
  }

  .tab-wrapper ul li {
    font-size: 13px;
    margin-right: 0;
  }
  .tab-wrapper ul {
    margin-top: 40px;
  }

  .notify-box::after {
    content: inherit;
    position: inherit;
  }
  .profile-detail-wrapper .monitioring-detail-box > div.test-meter-box {
    width: 220px;
    margin: 0 auto;
    padding: 0px;
  }
  .profile-detail-wrapper .monitioring-detail-box > div.test-meter-box .test-meter-link {
    padding: 10px;
  }

  .profile-detail-wrapper .notification-doc-box {
    padding: 0 32px;
  }
  .profile-detail-wrapper .monitoring-profile-box {
    border-bottom: 10px solid #f5f8fb;
  }
}

/* switch */
.on-off-case {
  display: flex;
  width: 200px;
  margin-top: 10px;
}

.no-padding-box{
  @include media-breakpoint-down(sm) {
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: -25px;
  }
}

@include media-breakpoint-down(sm) {
  .tab-wrapper ul li a span{
    margin-right: 15px;
  }
}
.conection{
  height: 37px;
  width: 247px;
  color: #4A4A4A;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 2px;
  line-height: 28px;
}

.monitoring-profile-box .monitioring-detail-box button.test-meter-link{
  margin-top: 45px;
  width: 220px !important;
}

.document-popUp{
  .header-popUp{
    color: #00142D;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 12px;
  }
  .search-header{
    height: auto;
    margin: 20px 0;
    color: #5f5a5a;
    .filter-option{
      display: inline;
      font-size: 13px;
      cursor: pointer;
      color: #ff6f47;
      &:hover{
        color: #5f5a5a;
        font-weight: 400;
      }
      &.selectedFolder{
        color: #5f5a5a;
      }
      i{
        margin: 0 5px;
      }
    }
  }
  .syn-search-box{
    border: 1px solid #a5a2a2;
    width: 100%;
    padding: 0 5px;
    color: black;
    .form-control {
      border-radius: 0;
      height: 25px;
      font-size: 12px;
      line-height: 14px;
      font-weight: 200;
      padding-right: 30px;
    }
    button {
      position: absolute;
      top: 0;
      right: 0;
      background-color: #E9E9E9;
      color: #9B9B9B;
      border-radius: 0;
      padding: 0px;
      width: 25px;
      height: 25px;
    }
    svg {
      margin-top: -5px;
    }
  }
  .select-folder-wrapper {
    h3 {
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      color: #9B9B9B !important;
      margin: 26px 0 8px 0;
      padding: 0;
    }
    .btn-wrapper {
      text-align: center;
      margin-top: 30px;
    }
    .select-folder-box{
      overflow-y: auto;
      max-height: 235px;
      padding: 0 10px;
      ul {
        margin: 0;
        padding: 0;
        li {
          list-style: none;
            .icon{
              background-image: url(../Assets/Images/folder-grey.png);
              width: 18px;
              height: 18px;
              background-size: 18px;
              display: inline-block;
              margin-right: 15px;
            }
            .folderName{
              font-size: 12px;
              line-height: 36px;
              font-weight: 400;
              color: #000000;
              align-items: center;
              margin: 0;
              &:hover {
                color: #00142D;
                cursor: pointer;
            }
            }
            ul{
              padding-left: 37px;
            }
            .selectedFolder{
              color: #00142D;
              font-size: 12px;
              line-height: 36px;
              font-weight: 400;
              align-items: center;
              margin: 0;
            }
        }
      }
    }
  }
}

i.anticon.anticon-check.ant-select-selected-icon{
  display: none !important;
}

.sider-button{
  position: fixed;
  top: 32px;
  left: 240px;
  z-index: 4;
  height: 26px;
  width: 26px;
  transition: all 0.2s;
  cursor: pointer;
  background-repeat: no-repeat;
  background-image: url(../Assets/Images/LFTcollapse_icon.svg);
  @include media-breakpoint-down(md) {
    display: none;
  }
}
.sider-button.sider2-collapse{
  left: 68px !important;
  background-image: url(../Assets/Images/RTcollapse_icon.svg);
}

.bodhi-sider{
  &::-webkit-scrollbar {
    width: 0px;
  }
  @include media-breakpoint-down(md) {
    display: none;
  }

  .ant-layout-sider-trigger{
    background: inherit;
  }

  overflow: auto !important;
  position: fixed !important;
  left: 0;
  top:  0px !important;
  bottom: 0px !important;
  z-index: 3 !important;
  max-width: 251px !important;
  background-color: #00142D !important;
  .ant-menu-dark,.ant-menu-dark .ant-menu-inline.ant-menu-sub{
    background-color: #00142D !important;
  }
 
  .ant-menu-item{
    display: inline-flex;
  }
  svg.sider-icon {
    margin-right: 12px;
    height: 20px !important;
    margin-top: 0;
    width: 20px;
    &.utilityIcon {
      margin-top: -5px;
    }
  }
  .ant-menu-item,.ant-menu-submenu-title {
    margin: 0;
    color: #D8D8D8;
    &:hover{
      color: #D8D8D8;
    }
  }
  .add-icon {
    position: absolute;
    right: 35px;
    width: 25px;
    text-align: center;
    z-index: 9999 !important;
    cursor: pointer;
    font-size: 20px;
  }

  .journey-menu {
    .ant-menu-submenu.ant-menu-submenu-open.ant-menu-submenu-selected {
      background-color: rgba(255, 255, 255, 0.1) !important;
    }
    li ul li {
      margin-top: 8px!important;
      margin-bottom: 0!important;
      &:hover {
        background-color: rgba(255, 255, 255, 0.1) !important;
      }
    }
  }
}

/**
 * nesting fixes
 */
ul.ant-menu ul.ant-menu {
  padding-left: 16px!important;
  li {
    div {
      padding-left: 26px!important;
    }
    ul.ant-menu {
      padding-left: 0!important;
    }
  }
}

.ant-menu-inline-collapsed {
  &-tooltip {
    display :none;
  }
}

.installer-view{
  width: calc(100% - 250px);
  margin-left: 250px;

  .admin-view{
    width: calc(100% - 250px) !important;
    margin-left: 250px;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    margin-left: 0px;
  }
  .nav-header-wrapper.dashboard-nav-wrapper.position-fixed{
    margin-left: -250px;
    @include media-breakpoint-down(md) {
      margin-left: 0px;
    }
    nav.navbar.navbar-expand-lg.navbar-light.bg-light{
      padding-left: 19%;
    }
  }
}

.sider-collapse{
  width: calc(100% - 80px) !important;
  margin-left: 80px;

  .admin-view{
    width: calc(100% - 80px);
    margin-left: 80px;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    margin-left: 0px;
  }
  .nav-header-wrapper.dashboard-nav-wrapper.position-fixed{
    margin-left: -80px;
    @include media-breakpoint-down(md) {
      margin-left: 0px;
    }
    nav.navbar.navbar-expand-lg.navbar-light.bg-light{
      padding-left: 8%;
    }
  }

}

//  sider css
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: initial  !important;
  color: #D8D8D8 !important;
}

.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before{
  background: #D8D8D8 !important;;
}

.ant-menu-item:hover, 
.ant-menu-item-active, 
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, 
.ant-menu-submenu-active, .ant-menu-submenu-title:hover{
  color: #FFD110 !important;
}

.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  display: inline-flex;
  margin-bottom: 8px;
}

.ant-menu-dark .ant-menu-submenu-title 
.ant-menu-submenu-arrow, 
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow{
  opacity: 1;
}
.ant-menu-dark .ant-menu-item, 
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-submenu,.ant-menu-submenu-active, 
.ant-menu-dark .ant-menu-item > a{
  color: #D8D8D8 !important;
}

.journey-menu.siderActive .ant-menu-submenu-title {
  margin-bottom: 6px!important;
  padding-top: 6px!important;
}

.journey-menu.siderActive .ant-menu-submenu-title,.ant-menu-dark .ant-menu-item:hover, .ant-menu-dark .ant-menu-item-group-title:hover, .ant-menu-submenu-active:hover, .ant-menu-dark .ant-menu-item > a:hover {
  color: #FFD110 !important;
}

.ant-menu-dark .ant-menu-item:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

ul.ant-menu.left-menu.journey-menu.ant-menu-dark.ant-menu-inline-collapsed.ant-menu-root.ant-menu-vertical{
  width: 100%;
}

    //  sider css
li.ant-menu-item.siderActive.ant-menu-item-selected{
  background-color: rgba(255,255,255,0.1)  !important;
  color: #FFD110 !important;
}

.seprator-right{
  border: none !important;
  border-right: 1px solid #DFE6EE !important;
  box-sizing: border-box;
}

.seprator-bottom{
  border: none !important;
  border-bottom: 1px solid #DFE6EE !important;
  box-sizing: border-box;
}

.ant-select-dropdown-menu .ant-select-dropdown-menu-item{
  font-size: 14px;
}

.link-Color{
  color: #ff6f47 !important;
  &.hover-link{
    border-color: #ff6f47 ;
    &:hover{
      color: white !important;
      background-color: #ff6f47;
    }
  }
}

.map-filter-box{
  position: absolute;
  background-color: white;
  top: 117px;
  z-index: 2;
  padding: 0 25px;
  box-sizing: border-box;
  min-width: 380px;
  .fleet-sider{
    position: absolute;
    top: 25px;
    z-index: 4;
    height: 26px;
    width: 26px;
    transition: all 0.2s;
    cursor: pointer;
    background-repeat: no-repeat;
    background-image: url(../Assets/Images/fleet_LFTcollapse_icon.svg);
    right: -13px !important;
    &.hide{
      left: 45px !important;
      right: 0px !important;
      background-image: url(../Assets/Images/fleet_RTcollapse_icon.svg);
    }
  }
  .detail-box{
    max-height: calc(100vh - 202px);
    .seprator-bottom :hover{
      background-color: #f7f7f7;
    }
  }

  .search-wrap{
    height: 33px;
    border: 1px solid #CCCCCC;
    border-radius: 10px;
    margin-top: 20px;
    padding: 3px 20px;
    img{
      margin-top: 4px;
    }
    input{
      color: #4a4a4a!important;
      padding-left: 12px!important;
      width: calc(100% - 25px);
      padding: 0;
      margin-top: -3px;
    }
    &.fleet{
      input{
        margin-top: 3px;
      }
      img{
        margin-top: 0;
      }
    }
  }
  .menuOption{
    padding: 15px 0;
    border-bottom: 1px solid #DFE6EE;
   
    .ant-dropdown-trigger{
      height: 21px;
      border: 1px solid #CCCCCC;
      border-radius: 6px;
      display: inline-block;
      padding: 0 10px;
      cursor: pointer;
      .dropdown-icon{
        float: right;
        margin-top: 2px;
        &.fa-times{
          color: #CCCCCC;
          font-size: 11px;
          margin-top: 4px;
          cursor: pointer !important;
        }
      }
      &:first-child{
        width: 139px;
      }
      &:last-child{
        width: 107px;
      }
      &:nth-child(2){
        width : 106px;
        margin: 0 13px;
      }
    }
  }
  .no-result-block{
    width: 250px;
    color: #7A7A7A;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    margin: 66px auto;
  }
  .text{
    text-align: left;
    letter-spacing: 0.26px;
    line-height: 17px;
    font-size: 14px;
    padding: 0;
    padding-left: 15px;
  }
  .img{
    height: 29px;
    width: 29px;
  }
}

.ant-dropdown{
  border: 1px solid #DFE6EE;
}

.fleet-viewType{
  height: 33px;
  width: 156px;
  border-radius: 13px;
  background-color: black;
  color: white;
  padding: 6px 4px;
  float: right;
  margin-top: 12px;
  margin-right: 12px;
  &.mapView{
    position: fixed;
    top: 120px;
    right: 80px;
    z-index: 2;
  }
  input {
    display: none;
  }
  #bckgrnd, .labels {
    width: 50%;
    
    text-align: center;
    // padding-top: 10px;
    cursor: pointer;
  }
  .labels {
    border-radius: 13px;
    line-height: 22px;
    font-size: 12px;
    font-weight: bold;
    &.checked{
      background-color:white;
      color: black;
    }
  }
  #bckgrnd {
    position:absolute;
    left:0;
    top:0;
    border-radius: 13px;
    z-index:-1;
    transition: left linear 0.3s;
  }
  #rad1:checked ~ #bckgrnd {
    left:0;
    color: #000 !important;
  }
  #rad2:checked ~ #bckgrnd {
    left:50%;
    color: #000 !important;
  }
}

.fleet-homeownerInfo{
  width: 400px;
  font-size: 14px;
}

.gm-style-iw-d{
  overflow: hidden !important;
}

.xAxisWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 25%;
  padding-right: 2%;

  .innerChild{
    text-align: center;
    margin: 0 1%;
    .prod{
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 20px;
    }
  }
}

.areachart-wrapper {
  display: flex;

  .chart-outer {
    width: calc(100% - 30px);
  }

  .custom-axis {
    flex-direction: column;
    display: flex;
    width: 45px;

    span {
      font-size: 14px;
      font-weight: 300;
      height: calc(33.333% + 40px);
      text-align: right;
      padding-top: 40px;
    }
  }
}

.areachart{
  border: 0.8px solid #E0E0E0;
  margin-top: -35px;
  position: relative;
  .area {
    opacity: 0.8 !important;
  }
  .area-chart{
    margin-top: 35px;
    padding-left: 10px;

    /**
     * This targets nodes within the svg element
     */
    .wrapper {
      .axis {
        display: none;
      }
    }
  }

 .empty-chart{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
    .fa{
      font-size: 48px;
      color: #CCCCCC;
    }
    p{
      color: #7A7A7A;
      font-size: 15px;
      font-weight: bold;
    }
 }
}

.alert-value{
  width: 65px
}